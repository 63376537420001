define("discourse/plugins/discourse-ai/discourse/routes/admin-plugins-show-discourse-ai-llms-new", ["exports", "discourse/routes/discourse"], function (_exports, _discourse) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _discourse.default.extend({
    async model() {
      const record = this.store.createRecord("ai-llm");
      record.provider_params = {};
      return record;
    },
    setupController(controller, model) {
      this._super(controller, model);
      controller.set("allLlms", this.modelFor("adminPlugins.show.discourse-ai-llms"));
    }
  });
});